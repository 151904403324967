import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";

const tableColsNotification = [
  {
    text: "ID",
    value: "id",
    width: 25,
    sort: true
  },
  {
    text: "Дата и время",
    value: "send_at",
    renderFunction: value =>
      moment(value.send_at * 1000).format("DD.MM.YYYY HH:mm:ss"),
    sort: true
  },
  {
    text: "Город",
    value: "by_city",
    // eslint-disable-next-line no-unused-vars
    renderFunction: value => value.by_city_details?.name,
    sort: true
  },
  {
    text: "Сообщение",
    value: "subject",
    renderFunction: value =>
      `<div class="d-flex flex-column" style="background-color: inherit; max-width: 600px"><span style="font-weight: 600">${value.subject}</span><sapn style="text-align: left;" >${value.text}</span></div>`,
    sort: true
  },

  {
    text: "Отправитель",
    value: "sender",
    renderFunction: value => value.sender_details?.full_name,
    sort: true
  },
  {
    text: "Получатели",
    renderFunction: value => value?.receivers?.length,
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20
  },
  {
    text: "Объект",
    value: "by_object",
    renderFunction: value => value.by_object_details?.name,
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Компания",
    value: "by_organization",
    // eslint-disable-next-line no-unused-vars
    renderFunction: value => value.by_organization_details?.name,
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дилер",
    value: "by_diller",
    // eslint-disable-next-line no-unused-vars
    renderFunction: value => value.by_diller_details?.name,
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true
  },
  {
    text: "Тип компании",
    value: "by_ownership"
  },
  {
    text: "Тип объекта",
    value: "by_object_type",
    renderFunction: value =>
      dictionariesHelper.objectType[value.by_object_details?.type],
    sort: true
  }
];

export default tableColsNotification;
