<template>
  <MainLayout>
    <template v-slot:navbar-title>Добавить</template>
    <template v-slot:default>
      <SlideLayout ref="slide" :tabs="slides">
        <template v-slot:create>
          <CreateFormLayout>
            <template v-slot:title__text>
              <!--              Ожидает реализации-->
              Добавление уведомления
            </template>

            <template v-slot:title-flex-right>
              <div></div>
            </template>

            <template #default="{handleSubmit}">
              <!--                  Данные компании    -->
              <div>
                <FormCardTitle class="data__text">
                  Данные уведомления
                </FormCardTitle>
                <FormInputBlock>
                  <ValidationAutocompleteField
                    label="Город"
                    v-model="city"
                    :search-function="citySearch"
                    validate-name="город"
                    v-if="[70, 30].includes($store.getters.getCurrentRole)"
                  />
                  <ValidationAutocompleteField
                    v-model="diller"
                    v-if="[70].includes($store.getters.getCurrentRole)"
                    :search-function="dillerSearch"
                    label="Дилер"
                    validate-name="дилер"
                  />
                  <ValidationSelectField
                    clearable
                    v-model="organization_type"
                    v-if="![30, 31, 21].includes($store.getters.getCurrentRole)"
                    :items="organizationType"
                    label="Тип компании"
                    validate-name="тип компании"
                  />
                  <ValidationAutocompleteField
                    v-model="organization"
                    v-if="![31, 30, 21].includes($store.getters.getCurrentRole)"
                    :search-function="companySearch"
                    label="Компания"
                    validate-name="компания"
                  />
                  <ValidationSelectField
                    clearable
                    v-model="object_type"
                    :items="objectsType"
                    label="Тип объекта"
                    v-if="[70, 30].includes($store.getters.getCurrentRole)"
                    validate-name="тип объекта"
                  />
                  <ValidationAutocompleteField
                    v-model="object"
                    :search-function="objectSearch"
                    label="Объект"
                    v-if="[70, 30].includes($store.getters.getCurrentRole)"
                    validate-name="объект"
                  />
                  <ValidationAutocompleteField
                    v-model="group"
                    :search-function="groupSearch"
                    label="Группа пользователей"
                    v-if="[70, 31, 30].includes($store.getters.getCurrentRole)"
                    validate-name="группа пользователей"
                  />
                  <ValidationAutocompleteField
                    v-model="childrenGroup"
                    :search-function="childrenGroupSearch"
                    label="Детская группа"
                    v-if="[21].includes($store.getters.getCurrentRole)"
                    validate-name="детская группа"
                    rules="required"
                  />

                  <ValidationAutocompleteField
                    v-model="service_organization"
                    :search-function="serviceCompanySearch"
                    label="Обслуживающая организация"
                    v-if="[70, 30].includes($store.getters.getCurrentRole)"
                    validate-name="обслуживающая организация"
                  />
                  <ValidationSelectField
                    clearable
                    v-model="device_type"
                    v-if="![30, 31, 21].includes($store.getters.getCurrentRole)"
                    :items="[
                      { text: 'Android', value: 'FCM' },
                      { text: 'iOS', value: 'APNS' }
                    ]"
                    label="Тип устройства"
                    validate-name="тип устройства"
                  />
                  <ValidationAutocompleteField
                    return-object
                    multiple
                    hide-selected
                    v-model="user"
                    :searchFunction="userSearch"
                    label="Телефон"
                  >
                    <template v-slot:selection=""></template>
                  </ValidationAutocompleteField>

                  <ValidationFileField
                    v-model="file"
                    accept=".xlsx"
                    label="Выбрать файл"
                    validate-name="выбрать файл"
                  />
                  <v-spacer></v-spacer>

                  <BasicTable
                    class=" grid-column_span_1 "
                    v-if="user?.length > 0"
                    :header="[
                      { text: 'Пользователь', value: 'text' },
                      {
                        text: 'Удалить',
                        width: 10,
                        renderFunction: () => `<a class='form__btn-delete'></a>`
                      }
                    ]"
                    @row_click="deleteUser($event.item.value)"
                    :table-data="user"
                  ></BasicTable>
                  <v-spacer v-if="user?.length > 0"></v-spacer>
                  <v-spacer v-if="user?.length > 0"></v-spacer>

                  <div class="grid-column_span_12 flex-ver-center">
                    <v-btn
                      :disabled="role == 21 && childrenGroup == null"
                      @click.stop="fetchUserCount"
                      class="btn btn_color_white btn_results"
                    >
                      Показать результаты
                    </v-btn>
                    <p
                      v-if="typeof user_count === 'number'"
                      class="text-info list__text-info"
                    >
                      Найдено {{ user_count }}
                    </p>
                  </div>

                  <ValidationInputField
                    class=" grid-column_1"
                    label="Тема сообщения"
                    v-model="subject"
                    rules="required"
                  />
                  <ValidationTextareaField
                    class=" grid-column_1 grid-column_span_2 "
                    rules="required|max:500"
                    v-model="text"
                    label="Сообщение"
                    validate-name="сообщение"
                  />
                  <!--                  <ValidationSelectField-->
                  <!--                    rules="required"-->
                  <!--                    v-model="type"-->
                  <!--                    clearable-->
                  <!--                    label="Тип уведомления"-->
                  <!--                    class="grid-column_1"-->
                  <!--                    :items="filteredTypeNotification"-->
                  <!--                  />-->
                  <div class="grid-column_1">
                    <v-btn
                      type="submit"
                      @click="handleSubmit(sendMessage)"
                      class="btn btn-green"
                    >
                      Отправить
                    </v-btn>
                  </div>
                </FormInputBlock>
              </div>
              <!--    Данные компании    -->
            </template>
          </CreateFormLayout>
        </template>
        <template v-slot:list>
          <TableFilters :queries="queries">
            <!--            @click="downloadList"-->
            <template v-slot:filter-title>
              <span v-if="sourceObject.count">
                <span class="title-filter">Всего:</span>{{ sourceObject.count }}
              </span>
            </template>
            <template v-slot:download-button>
              <v-btn
                @click="downloadList"
                class="btn btn_color_grey btn-unload flex-right mr-4"
                :disabled="loadingBtn || sourceObject.results.length == 0"
                :loading="loadingBtn"
              >
                Выгрузить
              </v-btn>
            </template>
          </TableFilters>
          <TableLayout
            :loading="isDateLoaded"
            disable-router
            :table-data="sourceObject.results"
            :header="header"
            :source-object="sourceObject"
          />
        </template>
      </SlideLayout>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField";
import { getCitiesTypesClearRequest } from "@/helpers/api/cities";
import { getOrganizationsClearRequest } from "@/helpers/api/organizations";
import { getObjectsClearRequest } from "@/helpers/api/objects";
import { getAccessGroupsClearRequest } from "@/helpers/api/access-group";
import { getChildrenGroupsRequest } from "../../helpers/api/childrenGroup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import tableColsNotification from "@/helpers/tableCols/notification";
import { getUsersClearRequest } from "@/helpers/api/user";
import BasicTable from "@/components/tables/BasicTable.vue";
import ValidationFileField from "@/components/ValidationField/ValidationFileField.vue";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";

export default {
  name: "MessagesPage",
  data() {
    return {
      loadingBtn: false,
      organizationTypes: Object.entries(
        dictionariesHelper.organizationOwnership
      ).map(([value, text]) => ({ value, text })),
      typeNotification: [
        {
          value: "push",
          text: "Push-сообщение",
          role: [21, 31, 30, 70, 60]
        }
      ],
      userSearch: async value => {
        return (
          await getUsersClearRequest({
            query: {
              search: value.replace(/[^\d+а-яА-ЯёЁ ]/g, "").slice(0, 13)
            }
          })
        ).data.results.map(el => ({
          text: `${el.phone_number}, ${el.full_name || ""}` || "",
          value: el
        }));
      },
      citySearch: async value => {
        return (
          await getCitiesTypesClearRequest({
            search: value,
            offset: null
            // type: "message"
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      serviceCompanySearch: async value => {
        return (
          await getOrganizationsClearRequest({
            query: { search: value, types: "service", city: this.city }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      dillerSearch: async value => {
        return (
          await getOrganizationsClearRequest({
            query: {
              search: value,
              types: ["diller", "oppen"],
              city: this.city
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      companySearch: async value => {
        return (
          await getOrganizationsClearRequest({
            query: {
              search: value,
              ownership: this.organization_type,
              diller: this.diller,
              types: ["default", "kindergarten"],
              city: this.city
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      objectSearch: async value => {
        return (
          await getObjectsClearRequest({
            query: {
              limit: 100000,
              search: value,
              city: this.city,
              type: this.object_type,
              organization: this.organization
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      childrenGroupSearch: async value => {
        return (
          await getChildrenGroupsRequest({
            query: {
              search: value,
              Teachers: this.role,
              kindergarten_org: this.organization
            }
          })
        ).data.results
          .filter(el => el.teachers.includes(this.currentUserId))
          .map(el => ({
            text: el.name,
            value: el.id
          }));
      },
      groupSearch: async value => {
        return (
          await getAccessGroupsClearRequest({
            query: {
              search: value,
              object__city: this.city,
              object: this.object,
              organization: this.organization
            }
          })
        ).data.results.map(el => ({
          text: el.title,
          value: el.id
        }));
      },
      objectsType: [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ]
    };
  },
  components: {
    ValidationFileField,
    BasicTable,
    ValidationTextareaField,
    ValidationAutocompleteField,
    ValidationSelectField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    SlideLayout,
    TableFilters,
    TableLayout,
    MainLayout
  },
  methods: {
    deleteUser(id) {
      console.log(id);
      console.log(this.user);
      this.user = this.user.filter(el => el.value !== id);
    },

    sendMessage() {
      this.$store
        .dispatch("sendMessage")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.send(successMessageDictionary.message)
          );
        })
        .finally(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchPageMessagesList").finally(() => {
            this.isDateLoaded = false;
          });

          this.$refs.slide.tab = 1;
        });
    },
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadMessagesPage", this.header).then(() => {
        this.loadingBtn = false;
      });
    },
    fetchUserCount() {
      if (this.curRole === 21) {
        this.$store.dispatch("fetchUserCountInChildrenGroup", {
          childrenGroupId: this.childrenGroup,
          organization: this.$store.getters.getCurrentOrganization
        });
      } else if (this.curRole === 30 || this.curRole === 31) {
        this.$store.dispatch("fetchUserCount", {
          by_organization: this.$store.getters.getCurrentOrganization
        });
      } else {
        this.$store.dispatch("fetchUserCount");
      }
    }
  },
  computed: {
    organizationType() {
      return this.organizationTypes;
    },
    queries() {
      return query.message.filter(el => el.userRole.includes(this.role));
    },
    currentUserId() {
      return this.$store.getters.getUserId;
    },
    header() {
      return tableColsNotification;
    },
    filteredTypeNotification() {
      return this.typeNotification.filter(el => {
        return el.role.includes(this.role);
      });
    },
    role() {
      return this.$store.getters.getCurrentRole;
    },
    slides() {
      return [
        { text: "Создать уведомление", value: "create" },
        {
          text: "Архив уведомлений",
          value: "list",
          count: this?.sourceObject?.count
        }
      ];
    },
    user_count() {
      return this.$store.getters.getCreateMessageForm.user_count;
    },
    sourceObject() {
      return this.$store.getters.getPageMessagesList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },
    childrenGroup: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_children_group;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_children_group",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getCreateMessageForm.phone_numbers;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "phone_numbers",
          value: newValue
        });
      }
    },
    file: {
      get() {
        return this.$store.getters.getCreateMessageForm.file;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "file",
          value: newValue
        });
      }
    },
    city: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_city;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_city",
          value: newValue
        });
      }
    },
    text: {
      get() {
        return this.$store.getters.getCreateMessageForm.text;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "text",
          value: newValue
        });
      }
    },
    subject: {
      get() {
        return this.$store.getters.getCreateMessageForm.subject;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "subject",
          value: newValue
        });
      }
    },
    type: {
      get() {
        return this.$store.getters.getCreateMessageForm.type;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "type",
          value: newValue
        });
      }
    },
    organization_type: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_ownership;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_ownership",
          value: newValue
        });
      }
    },
    device_type: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_push_device_type;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_push_device_type",
          value: newValue
        });
      }
    },
    diller: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_diller;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_diller",
          value: newValue
        });
      }
    },
    organization: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_organization;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_organization",
          value: newValue
        });
      }
    },
    object_type: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_object_type;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_object_type",
          value: newValue
        });
      }
    },
    service_organization: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_service_organization;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_service_organization",
          value: newValue
        });
      }
    },
    object: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_object;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_object",
          value: newValue
        });
      }
    },
    group: {
      get() {
        return this.$store.getters.getCreateMessageForm.by_group;
      },
      set(newValue) {
        this.$store.commit("setMessageCreateFormValue", {
          fieldName: "by_group",
          value: newValue
        });
      }
    },
    curRole() {
      return this.$store.getters.getCurrentRole;
    }
  },
  created() {
    this.$store.commit("setMessageCreateFormValue", {
      fieldName: "type",
      value: "push"
    });
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });

    if (this.curRole === 31 || this.curRole === 21) {
      this.organization = this.$store.getters.getCurrentOrganization;
      this.$store.dispatch("fetchKinderInfo").finally(() => {
        this.isDateLoaded = false;
      });
      this.$store.commit("setSearchQuery", {
        query: {
          by_organization: this.$store.getters.getCurrentOrganization
        }
      });
    }
    if (this.curRole === 60) {
      this.$store.commit("setMessageCreateFormValue", {
        fieldName: "by_diller",
        value: this.$store.getters.getCurrentOrganization
      });
    }
    this.isDateLoaded = true;

    this.$store.dispatch("fetchPageMessagesList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageMessagesList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearMessageForm");
    this.$store.commit("clearMessagesList", {
      listName: "pageMessagesList"
    });
    next();
  }
};
</script>

<style scoped></style>
